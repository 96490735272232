
import { ref, watch } from 'vue';
import CardTable from '@/components/kt-datatable/CardTable.vue';
import ApiService from '@/core/services/ApiService';
import { reinitializeComponents } from '@/core/plugins/keenthemes';

export default {
  name: 'CarLists',
  components: {
    CardTable,
  },
  setup() {
    const breadcrumbs = ['Car & Function', ['Car Lists']];
    const tableHeader = [
      {
        key: 'no',
        name: 'No.',
        sortable: true,
      },
      {
        name: 'Type',
        key: 'typeName',
        sortable: true,
      },
      {
        name: 'Tags',
        key: 'tags',
        sortable: true,
      },
      {
        name: 'Brand',
        key: 'brandName',
        sortable: true,
      },
      {
        name: 'Model',
        key: 'modelName',
        sortable: true,
      },
      {
        name: 'Sub Model',
        key: 'serieName',
        sortable: true,
      },
      {
        name: 'Year',
        key: 'name',
        sortable: true,
      },
      {
        name: 'Transmission',
        key: 'transmission',
        sortable: true,
      },
      {
        name: 'Actions',
        key: 'actions',
        sortable: false,
      },
    ];

    const currentIndex = ref(-1);
    const loopers = [
      'name',
      'transmission',
      'typeName',
      'brandName',
      'modelName',
      'serieName',
    ];

    const tableData = ref([]);
    const loading = ref(true);
    const tableRef = ref<any>(null);
    const filterData = ref<any>([]);

    watch(
      () => [tableRef.value?.rowsPerPage, tableRef.value?.currentPage],
      async ([rows, page]) => {
        loading.value = true;

        const carData = await ApiService.get(
          `car-year?page=${page}&pageSize=${rows}`
        );
        tableData.value = carData.data.datas;
        tableRef.value.total = carData.data.total;

        reinitializeComponents();
        loading.value = false;
      }
    );

    const customProperties = [
      { key: 'typeName', value: ['serie', 'model', 'type', 'name'] },
      { key: 'brandName', value: ['serie', 'model', 'brand', 'name'] },
      { key: 'modelName', value: ['serie', 'model', 'name'] },
      { key: 'serieName', value: ['serie', 'name'] },
    ];

    return {
      tableHeader,
      breadcrumbs,
      currentIndex,
      loopers,
      customProperties,
      tableData,
      loading,
      tableRef,
      filterData,
    };
  },
};
